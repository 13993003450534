<template>
  <div class="event-pages-content">
  <a-row class="align-items-center mb-4" style="margin: -30px; padding: 15px 30px 22px 30px; background: #fff;">
      <a-col :span="18">
        <h3 class="mb-0" style="font-size: 20px;">Events</h3>
      </a-col>
      <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end;">
        <a-button type="primary" class="ml-4" size="large" @click="openEventModal(null)">
            <a-icon type="plus" /> Create new
        </a-button>
      </a-col>
    </a-row>
    <!-- eslint-disable -->
    <a-card>
      <div class="d-flex align-items-center justify-content-between mb-4">
        <div class="d-flex align-items-center">
          <span>Filters:</span>
          <a-select
            v-model="filters.type.value"
            placeholder="Reactions"
            class="ml-2 mr-2"
            style="width: 150px;"
            :options="filters.type.list"
            @change="tableUpdate"
          ></a-select>
        </div>
        <div style="flex-grow: 1;">
          <a-input-search
            v-model="search"
            placeholder="Input search text"
            enter-button
            @change="tableUpdate"
          />
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="events"
        :rowKey="(record, index) => index"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :customRow="customRow"
      >
        <template slot="defaultLang" slot-scope="text, record">
          <!-- {{ checkDefaultLang(record.default_language.id) }} -->
          {{ record.default_language.short }}
        </template>
        <template slot="status" slot-scope="text, record">
            <span>
              <a-tag :key="record.status" :color="statusColor[record.status]">{{ record.status }}</a-tag>
            </span>
        </template>
        <template slot="actions" slot-scope="text, record">
          <div class="row-operations">
            <a-icon
              v-if="record.status === 'active'"
              type="bulb"
              theme="twoTone"
              two-tone-color="#31ba9a"
              class="font-size-18 mr-2"
            />
            <a-icon
              v-else
              type="bulb"
              theme="twoTone"
              two-tone-color="#ccc"
              class="font-size-18 mr-2"
            />
            <span>
              <a class="font-size-18 text-primary edit-icon" href="javascript:;"><a-icon type="edit" /></a>
            </span>
            <span>
              <a-popconfirm
                title="Sure to delete this event?"
                @confirm="() => deleteEventPage(record.id)"
              >
                <a class="font-size-18 text-danger delete-icon"  href="javascript:;"><a-icon type="delete" /></a>
              </a-popconfirm>
            </span>
          </div>
        </template>
      </a-table>
    </a-card>

    <event-modal
      ref="event-modal"
      :event="event"
      @cancel="eventModalHandleCancel"
      @update="tableUpdate"
    />
  </div>
</template>

<script>
import apiClient from '@/services/axios'
// import { mapState, mapActions } from 'vuex'
import { mapGetters, mapActions } from 'vuex'

const statusColor = { active: 'green', inactive: 'red' }
// const templateEvent = {
//   id: null,
//   url_name: '',
//   event_name: '',
//   status: 'active',
// }
const templateEvent = {
  id: null,
  event_name: '',
  event_id: '',
  status: 'active',
  default_lang_id: 0,
  // languages: [],
  // languages: [{ desc: 'Russian', id: 0, name: 'ru' }], //
  default_language: {
    id: 0,
    name: 'Russian',
    short: 'ru',
  },
  langs: [
    {
      id: 0,
      short: 'ru',
      name: 'Russian',
    },
  ],
  active_langs: null,
  langsName: [],
}

/* eslint-disable */
export default {
  components: {
    'event-modal': () => import('./EventModal.vue'),
  },
  data: () => ({
    event: { ...templateEvent },
    statusColor,
    events: [],
    pagination: {
      pageSize: 7,
      current: 1,
      total: 0,
    },
    search: '',
    filters: {
      type: {
        value: 'all',
        list: [
          {
            value: 'all',
            label: 'All',
          },
          {
            value: 'active',
            label: 'Active',
          },
          {
            value: 'inactive',
            label: 'Inactive',
          },
        ],
      },
    },
    tableLoading: false,
    columns: [
      {
        title: 'Event name',
        dataIndex: 'event_name', // заменить на event_name после нового api
        width: '35%',
      },
      {
        title: 'Event id',
        dataIndex: 'url_name', // заменить на event_id после нового api
        width: '30%',
      },
      // {
      //   title: 'Status',
      //   dataIndex: 'status',
      //   width: '25%',
      //   scopedSlots: { customRender: 'status' },
      // },
      {
        title: 'Default language',
        dataIndex: 'default_lang_id',
        width: '20%',
        scopedSlots: { customRender: 'defaultLang' },
      },
      {
        title: 'Actions',
        width: '15%',
        scopedSlots: { customRender: 'actions' },
      },
    ],
  }),
  async created() {
    await this.tableUpdate()
  },
  mounted() {
    if (this.langs.langData.length === 0) {
    // console.log(this.langs, 'mounted langs1')
      this.GET_LANGS()
    }
    // console.log(this.langs, 'mounted langs2')
    // console.log(this.langs, 'mounted langs')
  },
  computed: {
    // ...mapState(['langs']),
    ...mapGetters(['langs']),
    langData() {
      return this.langs.langData
    },
  },
  methods: {
    ...mapActions(['GET_LANGS']),
    // checkDefaultLang(id) {
    //   const lang = this.langs.langData.find(item => item.id === id)
    //   return lang.name
    // },
    // changeStatus(record) {
    //   // this.editStatus(record)
    //   console.log(record, 'changestatus') // при наличии api реализовать функционал
    // },
    changeType(value) {
      if (value === 'all' && this.tableFilters.type) delete this.tableFilters.type
      else {
        this.tableFilters.type = value
      }
      this.handleTableChange(this.pagination, this.tableFilters, this.tableSorter)
    },
    customRow(event) {
      const _this = this;

      return {
        on: {
          click: (e) => {
            if (e.target.closest('.edit-icon')) {
              // event.languages = this.langData
              event.langs.forEach((el, index) => {
                if (el.name === event.default_language.name) {
                  event.langs.splice(index, 1)
                }
              })
              event.langsName = event.langs.map((el) => {
                return el.name
              })
              console.log(event, 'event.langsName')
              _this.openEventModal(event);
              return false;
            }
            if (e.target.closest('.delete-icon')) {
              return false;
            }
            if (e.target.closest('.anticon-bulb')) {
              event.status = event.status === 'active' ? 'inactive' : 'active' // когда будет готово api добаить функционал
              console.log(event, 'bulb')
              this.editStatus(event)
              return false;
            }
            _this.$router.push(`/events/${event.id}`);
          },
        },
      }
    },
    async tableUpdate() {
      try {
        this.tableLoading = true
        await this.fetchEvents()
      } catch (e) {
        console.error(e)
      } finally {
        this.tableLoading = false
      }
    },
    // async getLang() {
    //   const url = '/admin/localizations'
    //   await apiClient.get(url).then((response) => {
    //     this.event.languages = response.data.data
    //   }).catch(error => { console.log(error) })
    // },
    // getLang() {
    //   this.$store.dispatch('GET_LANGS')
    //   // this.event.languages = this.langData
    // },
    async fetchEvents() {
      const { current, pageSize } = this.pagination
      const { data } = await apiClient.get(`admin/events?page=${current}&perPage=${pageSize}&search=${this.search}&state=${this.filters.type.value}`)
      this.events = data.data
      console.log(this.events, 'EVENTSDATA')
      this.pagination.total = data.meta.total
    },
    tableChange(e) {
      this.pagination = e
      this.tableUpdate()
    },
    async deleteEventPage(id) {
      try {
        this.tableLoading = true
        await apiClient.delete(`admin/events/${id}`)
        this.$notification.success({
          message: 'Success',
          description: 'Event deleted successfully',
          duration: 2,
        })
        this.tableUpdate()
      } catch (e) {
        this.$notification.error({
          message: 'Error',
          description: e.message,
        })
      } finally {
        this.tableLoading = false
      }
    },
    openEventModal(obj) {
      if (obj) {
        this.event = { ...obj }
        console.log(obj, 'OBJ')
      }
      this.$nextTick(() => {
        // this.event.languages = this.langData //test
        //
        // const activeLangs = this.event.langs.map((item) => {
        //   return item.id
        // })
        this.event.active_langs = [...this.langData]
        // this.langData.forEach((el, index) => {
        //   activeLangs.forEach((item) => {
        //     if (item === el.id) {
        //       this.event.active_langs.splice(index, 1)
        //     }
        //   })
        // })
        this.event.active_langs.forEach((el, index) => {
          if (el.id === this.event.default_lang_id) {
            this.event.active_langs.splice(index, 1)
          }
        })
        console.log(this.event.active_langs, 'this.event.active_langs1')
        console.log(this.langData, 'LANGDATA')
        this.$refs['event-modal'].showModal()
      })
    },
    eventModalHandleCancel() {
      setTimeout(() => {
        this.event = { ...templateEvent }
        console.log(this.event, 'AFTERCANSEL')
      }, 100)
    },
    onSelect() {
      console.log('onSelect')
    },
    async editStatus(payload) {
      console.log(payload, 'payload edit status')
      const backupLangs = payload.langs
      payload.langs = payload.langs.map((item) => {
        return item.id
      })
      console.log(payload, 'payload edit status2')
      try {
        this.loading = true
        await apiClient.patch(`admin/events/${payload.id}/edit`, { ...payload })
        // this.$notification.success({
        //   message: 'Success',
        //   description: 'Status edited successfully',
        // })
        // this.tableUpdate()
        // this.$emit('update')
        // this.$emit('cancel')
        // this.visible = false
        payload.langs = backupLangs // это костыль
      } catch (e) {
        this.$notification.error({
          message: 'Error',
          description: e.message,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
  .event-pages-content {
    .ant-table-row {
      cursor: pointer;
    }
  }
</style>
